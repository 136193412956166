import React from 'react';
import './LoadingSpinner.css';
import Lottie from 'react-lottie';
import * as animationData from '../../data.json'
import {  Card } from '@mui/material';
const LoadingSpinner = props => {

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    speed:1,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const cardStyle = {
    width:"90%",
    maxWidth:"420px",
    margin:"2rem auto 0"
  }
  
  return (
    <Card elevation={0} style={cardStyle}>
     <Lottie options={defaultOptions}
              height={300}
              width={300}
    />

    </Card>
  );
};

export default LoadingSpinner;
