import * as React from 'react'
import {Modal,Paper, Typography} from '@mui/material';
const modalStyle = {
  width:"90%",
  maxWidth:"420px",
  aspectRatio:"4 / 3",
  margin:"5rem auto",
  backgroundColor:"#161a1e",
  padding:"1rem",
  
  

}
const MioModal = props => {
  return (
    <Modal
    
    open={props.open}
    onClose={props.close}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    onBackdropClick={props.onBackdropClick}
    >
    <div className={`modal ${props.className}`} style={modalStyle}>
      <header className={`modal__header ${props.headerClass}`}>
        <Typography variant="h2" color="success" >{props.header}</Typography>
      </header>
      <form
        onSubmit={
          props.onSubmit ? props.onSubmit : event => event.preventDefault()
        }
      >
        <div style={{margin:"2rem 0"}}>
          {props.children}
        </div>
        <footer style={{marginTop:"auto"}}>
          {props.footer}
        </footer>
      </form>
    </div>
    </Modal>
  )
}

export default MioModal;