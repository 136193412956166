import { createTheme } from '@mui/material';

const darkTheme  = createTheme( {
  palette: {
    mode:'dark',
    type: 'dark',
    primary: {
      main: '#5e7cbf',
      contrastText: '#f1f3f6',
      light: '#5e7cbf',
      dark: '#5e7cbf',
    },
    secondary: {
      main: '#9575cd',
    },
    background: {
      default: '#13161a',
      paper: '#161a1e',
    },
    text: {
      primary: '#f1f3f6',
      secondary: 'rgba(241,243,246,0.75)',
      disabled: 'rgba(241,243,246,0.5)',
      hint: 'rgba(241,243,246,0.25)',
    },
    error: {
      main: '#ff1744',
    },
    warning: {
      main: '#ff9100',
    },
    info: {
      main: '#bbdefb',
    },
    success: {
      main: '#69f0ae',
    },
    divider: 'rgba(69,90,100,0.65)',
  },
  typography: {
    fontFamily: [
      '"Inter"',
      '"Dosis"',
    ].join(','),
    fontSize: 16,
    h1: {
      fontSize: '4.5rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '3.5rem',
      fontWeight: 600,
    },
    subtitle1: {
      fontFamily: 'Dosis',
      fontSize: '1.2rem',
      fontWeight: 600,
    },
    body1: {
      fontFamily: 'Dosis',
      fontSize: '1.2rem',
      fontWeight: 400,
    },
    body2: {
      fontFamily: 'Dosis',
    },
    subtitle2: {
      fontSize: '1.2rem',
      fontWeight: 600,
    },
    button: {
      fontWeight: 600,
    },
    overline: {
      fontWeight: 200,
      fontSize: '0.8rem',
    },
    h3: {
      fontSize: '3rem',
      fontWeight: 600,
    },
    h4: {
      fontFamily: 'Dosis',
      fontWeight: 600,
      fontSize: '2.25rem',
    },
    h5: {
      fontSize: '1.8rem',
      fontWeight: 200,
    },
    h6: {
      fontSize: '1.2rem',
      fontWeight: 600,
    },
  },
});

export default darkTheme;