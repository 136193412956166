

import React from 'react'
import MioModal from './MioModal'
import {Stack, Button, Typography, Link} from '@mui/material'

const CookiesBanner = props => {
  return (

    <MioModal
    open={props.isCookies}
    header={"🍪 Are the best!"}
    footer={<>
        <Stack spacing={1}>
            <Button variant='outlined' color="warning" onClick={props.setCookies}>Consent required Cookies</Button>
            <Button variant='contained' color="success" onClick={props.setCookies}>Consent Cookies</Button>
        </Stack>
    </>}
>
    <Typography variant="text">We use cookies to optimize your user experience. By continuing to use our website, you consent to our use of cookies in accordance with our <Link href="https://privacy.miomideal.com" >Cookie Policy</Link>.</Typography>
</MioModal>
  )
}

export default CookiesBanner