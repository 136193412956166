import React from 'react';
import MioModal from './MioModal';
import { Button, Typography} from '@mui/material'

const ErrorModal = props => {
  return (
    <MioModal
      close={props.onClear}
      header="An Error Occurred!"
      open={!!props.error}
      className="error-modal"
      footer={<Button variant="contained" color="error" onClick={props.onClear}>Okay</Button>}
    >
      <Typography sx={{margin:"1rem 0"}}  color="action">{props.error}</Typography>
    </MioModal>
  );
};

export default ErrorModal;