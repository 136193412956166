import * as React from "react";
import { TextField } from "@mui/material";
import { validate } from '../util/validators';

const inputReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE':
      return {
        ...state,
        value: action.val,
        isValid: validate(action.val, action.validators)
      };
    case 'TOUCH': {
      return {
        ...state,
        isTouched: true
      }
    }
    default:
      return state;
      }
};

const Input = props => {

    const [inputState, dispatch] = React.useReducer(inputReducer, {
        value: props.initialValue || '',
        isTouched: false,
        isValid: props.initialValid || false
      });
    
      const { id, onInput } = props;
      const { value, isValid } = inputState;
    
      React.useEffect(() => {
        onInput(id, value, isValid)
      }, [id, value, isValid, onInput]);
    
      const changeHandler = event => {
        dispatch({
          type: 'CHANGE',
          val: event.target.value,
          validators: props.validators
        });
      };
    
      const touchHandler = () => {
        console.log("is touched!");
        dispatch({
          type: 'TOUCH'
        });
      };

    return(

            <TextField
                id={props.id} 
                label={props.label}
                value={inputState.value}
                style={{width: '100%'}}
                margin="dense"
                variant="outlined"
                readOnly={props.honeypot}
                onChange={changeHandler}
                onBlur={touchHandler}
                list={props.dataList}
                type={props.type}
                multiline={props.multiline}
                rows={props.rows}
                error={inputState.isTouched && !inputState.isValid}
                helperText={inputState.isTouched && !inputState.isValid && props.helperText}
            />
    
    )
}

export default Input;