import React from 'react'
import {Card,Box,Avatar,Typography,Grid,Divider, Link} from '@mui/material'
import logo from '../../logo.svg'
import corpLogo from '../../corpLogo.svg'
import wordingLogo from '../../logo_wording.svg'


const SubjectCard = props => {
  return (
    <Grid sx={{ width: "100%" }} item md={6}>
    <Card sx={{ p: 2 }} elevation={0}>
    <Box sx={{ display: "flex", justifyContent: "space-between", height:"70px", alignItems: "center" }}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <img src={logo} alt="logo" style={{width:"100%", height:"100%", maxHeight:"45px"}} />
        </Box>
        <Typography variant='h4' sx={{ mb: 2, textAlign: "right" }} color="secondary">Subject #{props.id}</Typography>

    </Box>
    <Divider sx={{ my: 2 }} />
    <Grid container spacing={1} >
        <Grid item sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "flex-start", width: "100%", }} xs={4}>
            <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column", alignItems: "flex-start", width: "80%", aspectRatio: "1/1" }}>

                <Avatar sx={{ width: "100%", height: "100%" }} src={props.img} />
            </Box>
            <Box>
                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "flex-start", width: "70%" }}>

                    <Avatar variant="square" sx={{ width: "100%", height: "100%" }} src={wordingLogo} />
                </Box>
                <Typography variant='h6' color="error">{props.rank}</Typography>

            </Box>

        </Grid>
        <Grid item sx={{ width: "100%" }} xs={8}>
            <Box sx={{ fontFamily: 'Monospace' }}>
                <Card sx={{ p: 2, position: "relative", display: "flex", flexDirection: "column" }}>
                    <Box>
                        <Typography variant="h6" color="primary">User:</Typography>
                        <Typography variant="body2" color="white">{props.user}</Typography>
                    </Box>
                    <Box>
                        <Typography variant="h6" color="primary">Contact:</Typography>
                        <Typography variant="body2" ><Link href="mailto:info@miomideal.com">{props.email}</Link></Typography>
                    </Box>
                    <Box >
                        <Typography variant="h6" color="primary">Age:</Typography>
                        <Typography variant="body2" color="white">{props.age}</Typography>
                    </Box>
                    <Box >
                        <Typography variant="h6" color="primary">Profession:</Typography>
                        <Typography variant="body2" color="white">{props.profession}</Typography>
                    </Box>
                    <Box >
                        <Typography variant="h6" color="primary">Location:</Typography>
                        <Typography variant="body2" color="white">{props.location}</Typography>
                    </Box>
                    <Box >
                        <Typography variant="h6" color="primary">Race:</Typography>
                        <Typography variant="body2" color="secondary">{props.race}</Typography>
                    </Box>


                    <Box sx={{ position: "absolute", right: "0.5rem", bottom: "0.5rem", display: "flex", justifyContent: "center", height: "35px", alignItems: "center", opacity: "0.25" }}>
                        <Avatar variant="square" sx={{ width: "100%", height: "100%" }} src={corpLogo} />
                    </Box>
                </Card>



            </Box>
        </Grid>
    </Grid>
</Card>
</Grid>
  )
}

export default SubjectCard